import React from 'react'
import ReactDOM from 'react-dom'
import WithRouterApp from './App'
import {BrowserRouter as Router} from 'react-router-dom'
import { ApolloProvider } from "@apollo/react-hooks";

import './index.scss';
import App from './App'
import client from "./utils/apolloClient";

import {ThemeContextProvider} from './context/themeContext'

ReactDOM.render(
  <ThemeContextProvider>
    <Router>
      <ApolloProvider client={client}>
        <WithRouterApp />
      </ApolloProvider>
    </Router>
  </ThemeContextProvider>,
  document.getElementById('root')
)
