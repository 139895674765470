import React from 'react'

function Footer(){

    const date = new Date().getFullYear()

    const socialFooter = [
        {
            label: "Instagram",
            url: "https://www.instagram.com"
        },
        {
            label: "LinkedIn",
            url: "https://www.linkedin.com"
        },
        {
            label: "Twitter",
            url: "https://www.twitter.com"
        }
    ]

    const displaySocialFooter = socialFooter.map((item, index) => <li key={index}><a href={item.url}>{item.label}</a></li>)

    return(
        <section id="sectionFooter">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-2 col-xl-3 footer-logo">
                        <h3>CGC Investment.</h3>
                    </div>
                    <div className="col-12 col-md-4 col-lg-3 col-xl-3 footer-contact-box">

                        <div>
                            <h6>Sales inquiries</h6>
                            <h5><a href="mailto:contact@liquet.com">sales@cgcinvestment.com</a></h5>
                        </div>
                    </div>
                    <div className="col-12 col-md-3 col-lg-3 col-xl-3 footer-contact-box footer-address-box">
                        <div>
                            <h6>Location</h6>
                            <h5 className="text-muted">Gikondo <br /> Kigali Rwanda</h5>
                        </div>
                        <div>
                            <h6>Phone</h6>
                            <h5><a href="mailto:contact@liquet.com">(250) 784 684 853</a></h5>
                        </div>
                    </div>
                    <div className="col-12 col-md-5 col-lg-4 col-xl-3 social-footer-menu">
                        <ul>
                            {displaySocialFooter}
                        </ul>
                        <p>&copy; {date} CGC Investment. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer