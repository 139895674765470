import React from 'react'
import {NavLink} from 'react-router-dom'

const mainMenuLinks = [
    {
        label: 'Home',
        url: '/'
    },
    {
        label: 'About',
        url: '/about'
    },
    {
        label: 'Products',
        url: '/products'
    },
    // {
    //     label: 'Solutions',
    //     url: '/solutions'
    // },
    {
        label: 'Partners',
        url: '/partners'
    },
    // {
    //     label: 'Downloads',
    //     url: '/downloads'
    // },
    // {
    //     label: 'News',
    //     url: '/news'
    // },
    {
        label: 'Contact',
        url: '/contact'
    }
]

const displayLinks = mainMenuLinks.map((item, index) => <li className="nav-item" key={index}><NavLink className="nav-link" exact={true} to={item.url} activeClassName="active">{item.label}</NavLink></li>)

function MainMenu(){
    return(
        <div>
            <ul className="navbar-nav">
                {displayLinks}
            </ul>
        </div>
    )
}

export default MainMenu