import React from 'react'
import {Link} from 'react-router-dom'

import {Nav,NavDropdown,Navbar,Container} from "react-bootstrap"
import MainMenu from '../elements/MainMenu'


function Header(){
    return(
        <div>
            <Navbar className="header" expand="lg">
                <Container className="header-anim">
                    <Navbar.Brand href="#home">
                        <img src={`/assets/images/cgclogo.png`} alt="CGC Logo" className="w-auto" style={{height: '3.5rem'}} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="justify-content-end flex-grow-1 main-menu">
                        <MainMenu />
                    </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default Header