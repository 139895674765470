import React, {useState, useEffect} from 'react'

function Partners(){

    const PartnersInfo = [
        {
            label: "Eaton",
            url: "https://www.eaton.com",
            image: "https://www.eaton.com/content/dam/eaton/global/logos/eaton-logo-small.png"
        },
        {
            label: "Hikvision",
            url: "https://www.hikvision.com",
            image: "https://us.hikvision.com/sites/all/themes/hikvision_new/logo.png"
        },
        {
            label: "Siemon",
            url: "https://www.siemon.com",
            image: "https://www.siemon.com/ResourcePackages/Siemon/assets/dist/images/template/logo-2x.png"
        },
        {
            label: "NAFFCO",
            url: "https://www.naffco.com/uae/en/",
            image: "https://www.naffco.com/templates/img/logo.png"
        },
        {
            label: "Daikin",
            url: "https://www.daikin.com/",
            image: "https://www.daikin.com/-/media/Project/Daikin/daikin_com/dg_common/images/logo_daikin-png.png"
        }
    ]

    const displayPartnerLogos = PartnersInfo.map((item, index) => <div className="col-lg-3 col-6 mt-4" key={index}><a href={item.url}><img className="h-auto ml-4 partners-image" src={item.image} alt={item.label} /></a></div>)

    return(
        <section id="portfolioSection" className="section">
            <div className="container">

                <div className="row">
                    <div className="col-12 col-lg-6 portfolio-main-title anim-bot">
                        {/* <h1 className="section-big-title">Our partners</h1> */}
                    </div>
                </div>
                <div className="row works-wrapper align-items-center">
                {displayPartnerLogos}
                </div>

            </div>
        </section>
    )
}

export default Partners