import React, {useEffect, useContext} from 'react'
import {Link, useParams, useLocation} from 'react-router-dom'
import Icofont from 'react-icofont';
import {motion} from "framer-motion";

import {ThemeContext} from '../context/themeContext'
import Header from '../components/Header'
import Hero from '../components/Hero'
import SingleWorkGallery from '../components/SingleWorkGallery'
import HomeCta from '../components/HomeCta'
import SingleWorkStats from '../components/SingleWorkStats'
import SingleWorkRelated from '../components/SingleWorkRelated'
import Footer from '../components/Footer'

import works from '../data/WorksData.json'

function SingleWork() {

    const {afterLoading, onScrollGreenAnim, onScrollBottomAnim} = useContext(ThemeContext)

    useEffect(() => {
        afterLoading()
    }, [])

    useEffect(() => {
        window.addEventListener("scroll", onScrollBottomAnim);
        return () => window.removeEventListener("scroll", onScrollBottomAnim);
    }, [])

    useEffect(() => {
        window.addEventListener("scroll", onScrollGreenAnim);
        return () => window.removeEventListener("scroll", onScrollGreenAnim);
    }, [])

    const {productsId} = useParams()
    const thisWork = works.find(element => element.slug == productsId)
    console.log(thisWork)
    const displayWorks = thisWork.products.map((item, i) => {
        const slug= item.slug
        return (
            <div className="row">
                {/* <span><Link className="h3" to={`${productsId}/${item.slug}`}>{item.name}</Link></span> */}
                {/* <p>{item.overview}</p> */}
                {item.items.map((item, i) =>
                    <div className="col-lg-3 my-4"><div className="card"><img src={item.image} className="card-img-top py-2" alt={item.slug} /><div className="card-body"><a href={item.catalog} target="_blank" rel="noreferrer">{item.name}</a></div></div></div>
                )}
            </div>
        )
    })

    return(
        <div>
            <motion.div 
                initial={{y:0}}
                animate={{y:'-100%'}}
                exit={{y:0}}
                transition={{ duration: 1, ease: [0.43, 0.13, 0.23, 0.96] }}
                className="page-trans">
            </motion.div>
            <Header />
            <Hero type="short">
                <div className="row single-work-hero text-light">
                    <div className="col-4">
                        <h1>
                            {thisWork.name}
                        </h1>
                    </div>
                    <div className="col-8">
                        <span className="lead">{thisWork.overview}</span>
                    </div>
                </div>
            </Hero>
            <section id="homeCtaSection" className="section">
                <div className="container-md">
                    {displayWorks} 
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default SingleWork