import React from 'react'

function HomeCta(){
    return(
        <section id="homeCtaSection" className="section">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-5">
                        <h1 className="section-big-title anim-bot">In the digital age <br /> Your brand is always in beta.</h1>
                        <ul className="anim-bot">
                            <li>Award Winning Agency</li>
                            <li>Aesthetics Oriented Team</li>
                            <li>Marketing Strategy Experts</li>
                        </ul>
                    </div>
                    <div className="col-1"></div>
                    <div className="col-12 col-md-6">
                        <div className="anim-bot">
                            <p>It’s a constant work in progress. Enim ad minim veniam, quis nost exercitation ullamco laboris nisi ut aliquip ex ea commodo conse occaecat cupidatat non proident, sunt in culpa qui officia.</p>
                            <p>Rather than trying to get things perfect the first time, similique sut in culpa qui officia deserunt mollitia animi, id est laborum et dolor fuga. Et harum quidem rerum facilis est et expedita distinctio cum soluta nobis est eligendi optio cumque nihil impedit quo minus id.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeCta