import React from 'react'

function Hero(props){
    return(
        <section 
            id="heroSection" 
            className={props.type}
            style={{ 
                backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/images/hero.jpg'})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: "cover"
            }}
        >
            <div
                style={{ 
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'rgba(96, 96, 96, 0.4)'
                }}
            >
                <div className="container">{props.children} </div>
            </div>
        </section>
    )
}

export default Hero