import React, {useContext, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {motion} from 'framer-motion'
import Icofont from 'react-icofont'

import {ThemeContext} from '../context/themeContext'
import Header from '../components/Header'
import Hero from '../components/Hero'
import HomeCta from '../components/HomeCta'
import Team from '../components/Team'
import Services from '../components/Services'
import ToContact from '../components/ToContact'
import Footer from '../components/Footer'

function About(){

    const windowWidth = window.innerWidth
    const {afterLoading, afterLoadingImg, onScrollGreenAnim, onScrollBottomAnim} = useContext(ThemeContext)

    useEffect(() => {
        afterLoading()
    }, [])

    // useEffect(() => {
    //     afterLoadingImg()
    // }, [])

    useEffect(() => {
        window.addEventListener("scroll", onScrollBottomAnim);
        return () => window.removeEventListener("scroll", onScrollBottomAnim);
    }, [])

    useEffect(() => {
        window.addEventListener("scroll", onScrollGreenAnim);
        return () => window.removeEventListener("scroll", onScrollGreenAnim);
    }, [])

    return(
        <div>
            <motion.div 
                initial={{y:0}}
                animate={{y:'-100%'}}
                exit={{y:0}}
                transition={{ duration: 1, ease: [0.43, 0.13, 0.23, 0.96] }}
                className="page-trans">
            </motion.div>
            <Header />
            <Hero type="short">
                <div className="row">
                    <div className="col-12">
                        <h1 className="hero-title">
                            About us
                        </h1>
                        {/* <p className="hero-title">
                            We are a small remote cum soluta nobis est eligendi optio cumque nihil impedit <br /> quo minus id quod maxime placeat facere possimus.
                        </p>
                        <div className="hero-title hero-button">
                            <Link to="/">Our work <Icofont icon="thin-double-right" /> </Link>
                        </div> */}
                    </div>
                </div>
            </Hero>
            <section id="homeCtaSection" className="section">
                <div className="container-md">
                    <div className="row">
                        <h1 className="section-big-title anim-bot">About us</h1>
                        <div className="col-12">
                            <div className="anim-bot">
                                <p>Born in 2020, CGC Investments Ltd aims at providing constructive and sustainable solutions to electrical and mechanical matters.</p>
                                <p>Today, the world runs on critical infrastructure and technology. At CGC Investment Ltd, we are dedicated to improving people’s lives as well as the environment with power management technologies that are more reliable, efficient, safe, and sustainable.</p>
                                <p>We help clients meet the demands of the world’s ever-growing need for power through improving efficiency and uptime, meeting regulatory requirements and protecting people and equipment. Our electrical products help utilities generate, transmit and distribute power.</p>
                                <p>Once the power is distributed, we help people use it efficiently and safely, whether it is a building, industrial facility, data center or home. Our solutions range from circuit breakers to switchgear to machine controls. We offer a full portfolio of backup power and surge protection. We provide maximum uptime and guarding against power surges and outages. In hazardous environments we help protect people and property with explosion-proof enclosures, signaling, and notification. Our end-to-end electrical solutions are complemented by our full-scale engineering and support services.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="homeCtaSection" className="section">
                <div className="container-md">
                    <div className="row">
                        <h1 className="section-big-title anim-bot">Our Goal</h1>
                        <div className="col-12">
                            <div className="anim-bot">
                                <p>To boost the Rwandan economy through business within and with other regions in the world</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="homeCtaSection" className="section">
                <div className="container-md">
                    <div className="row">
                        <h1 className="section-big-title anim-bot">Our Vision</h1>
                        <div className="col-12">
                            <div className="anim-bot">
                                <p>To become a leading, exemplary company of excellence in electrical and mechanical solutions by providing real time solutions to our clients</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="homeCtaSection" className="section">
                <div className="container-md">
                    <div className="row">
                        <h1 className="section-big-title anim-bot">Our mission</h1>
                        <div className="col-12">
                            <ul className="anim-bot">
                                <li>To provide sustainable solutions to our clients to effectively manage electrical, hydraulic, transformers, switchgear and mechanical power more safely, more efficiently, and more reliably.</li>
                                <li>To provide valued services in Rwanda and beyond.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section id="homeCtaSection" className="section">
                <div className="container-md">
                    <div className="row">
                        <h1 className="section-big-title anim-bot">Our Core Values</h1>
                        <div className="col-12">
                            <div className="anim-bot">
                                <p>CGC Investment is based on the following principles: Passion| Integrity and Accountability | Determination and Diversity | Learning and Transparency</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Team />
            <ToContact /> */}
            <Footer />
        </div>
    )
}

export default About