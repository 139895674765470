import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'

import ProductItem from '../elements/WorkItem'

import Products from '../data/WorksData.json'

function Works(){

    // const [projects, setProjects] = useState([])

    // useEffect(() => {
    //     setProjects([works.items])
    // }, []);


    const displayWorks = Products.map((item, i) => <ProductItem key={i} title={item.name} category={item.category} image={item.image} slug={item.slug}/>)
    // const displayWorks = Products.map((item, i) => <li><Link to={`/products/${item.slug}`}>{item.name}</Link></li>)

    return(
        <section id="portfolioSection" className="section">
            <div className="container">

                <div className="row">
                    <div className="col-12 col-lg-6 portfolio-main-title anim-bot">
                        <h1 className="section-big-title">Products categories</h1>
                    </div>
                    <div className="col-12 col-lg-6 portfolio-filters anim-bot">
                        {/* <nav className="nav portfolio-nav anim-bot">
                            {worksFilters.map((item, i) => <a key={i} className={`nav-item ${filter === item ? 'active' : null}`} onClick={() => setFilter(item)}>{item}</a>)}
                        </nav> */}
                    </div>
                </div>
                <div className="row works-wrapper  anim-bot">
                    {displayWorks}
                </div>

            </div>
        </section>
    )
}

export default Works