import React from 'react'
import {Link} from 'react-router-dom'

function WorkItem({slug, image, title, category}){
    return(
        <div className={`col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 portfolio-box ${category}`}>
            <div className="anim-bot">
                <div className="cancel-anchor"></div>
                <Link to={`/products/${slug}`}>
                    <div className="portfolio-image">
                        <div className="work-img-wrapper">
                            <img src={`assets/images/${image}`} alt="" />
                        </div>
                        <div className="portfolio-text row">
                            <h3 className="col">{title}</h3>
                            <h6 className="col-auto">{category}</h6>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default WorkItem