import React, {useEffect} from 'react'
import {Switch, Route, useLocation, withRouter} from 'react-router-dom'
import {AnimatePresence} from 'framer-motion'

import Home from './pages/Home'
import SingleWork from './pages/SingleWork'
import About from './pages/About'
import News from './pages/News'
import Products from './pages/Products'
import ProductCategory from './pages/ProductCategory'
import ProductDetails from './pages/ProductDetails'
import SingleNews from './pages/SingleNews'
import Contact from './pages/Contact'
import Page404 from './pages/404'
import Solutions from './pages/Solutions'
import Partners from './pages/Partner'

function App() {

  const location = useLocation()

  useEffect(() => { setTimeout(()=>{ window.scrollTo(0, 0) }, 1000) }, [location])

  return (
    <div>
      <AnimatePresence initial={true} exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route exact path='/'><Home /></Route>
          <Route exact path='/products/:productsId'><SingleWork /></Route>
          <Route exact path='/products/:productsId/:categoryId'><ProductCategory /></Route>
          <Route path='/products/:productsId/:categoryId/:productId'><ProductDetails /></Route>
          <Route exact path='/about'><About /></Route>
          <Route exact path='/products'><Products /></Route>
          <Route exact path='/news'><News /></Route>
          <Route path='/single-news-:newsId'><SingleNews /></Route>
          <Route exact path='/contact'><Contact /></Route>
          <Route exact path='/solutions'><Solutions /></Route>
          <Route exact path='/partners'><Partners /></Route>
          <Route ><Page404 /></Route>
        </Switch>
      </AnimatePresence>
    </div>
  )
}

const WithRouterApp = withRouter(App);
export default WithRouterApp
