import React, {useEffect, useContext} from 'react'
import {Link, useParams} from 'react-router-dom'
import Icofont from 'react-icofont';
import {motion} from "framer-motion";

import {ThemeContext} from '../context/themeContext'
import Header from '../components/Header'
import Hero from '../components/HeroContent'
import SingleWorkGallery from '../components/SingleWorkGallery'
import HomeCta from '../components/HomeCta'
import SingleWorkStats from '../components/SingleWorkStats'
import SingleWorkRelated from '../components/SingleWorkRelated'
import Footer from '../components/Footer'

import works from '../data/WorksData.json'

function SingleWork() {

    const {afterLoading, onScrollGreenAnim, onScrollBottomAnim} = useContext(ThemeContext)

    useEffect(() => {
        afterLoading()
    }, [])

    useEffect(() => {
        window.addEventListener("scroll", onScrollBottomAnim);
        return () => window.removeEventListener("scroll", onScrollBottomAnim);
    }, [])

    useEffect(() => {
        window.addEventListener("scroll", onScrollGreenAnim);
        return () => window.removeEventListener("scroll", onScrollGreenAnim);
    }, [])

    const {productsId,categoryId} = useParams()
    const thisWork = works.find(element => element.slug == productsId)
    const theWork = thisWork.products.find(element => element.slug == categoryId)
    const displayWorks = theWork.items.map((item, i) => <div className="col-lg-3"><><Link to={`${categoryId}/${item.slug}`}>{item.name}</Link></></div>)

    // const updatedWorks = works.items.filter(element => element !== thisWork)
    // const relatedWorks = updatedWorks.filter(element => element.category === thisWork.category)

    return(
        <div>
            <motion.div 
                initial={{y:0}}
                animate={{y:'-100%'}}
                exit={{y:0}}
                transition={{ duration: 1, ease: [0.43, 0.13, 0.23, 0.96] }}
                className="page-trans">
            </motion.div>
            <Header />
            <Hero type="short">
                <div className="row single-work-hero">
                    <div className="col-12 col-md-12 col-lg-7 col-xl-8">
                        <h2>
                            {thisWork.name}
                        </h2>
                        <span>{thisWork.overview}</span>
                    </div>
                </div>
            </Hero>
            <section id="homeCtaSection" className="section">
                <div className="container">
                    <div className="row">
                        {displayWorks} 
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default SingleWork