import React, {useContext, useEffect} from 'react'
import {motion} from 'framer-motion'

import {ThemeContext} from '../context/themeContext'
import Header from '../components/Header'
import Works from '../components/Works'
import Footer from '../components/Footer'

function Products(){

    const {afterLoading, onScrollGreenAnim, onScrollBottomAnim} = useContext(ThemeContext)

    useEffect(() => {
        afterLoading()
    }, [])

    useEffect(() => {
        window.addEventListener("scroll", onScrollBottomAnim);
        return () => window.removeEventListener("scroll", onScrollBottomAnim);
    }, [])

    useEffect(() => {
        window.addEventListener("scroll", onScrollGreenAnim);
        return () => window.removeEventListener("scroll", onScrollGreenAnim);
    }, [])

    return(
        <div>
            <motion.div 
                initial={{y:0}}
                animate={{y:'-100%'}}
                exit={{y:0}}
                transition={{ duration: 1, ease: [0.43, 0.13, 0.23, 0.96] }}
                className="page-trans">
            </motion.div>
            <Header />
            <Works />
            <Footer />
        </div>
    )
}

export default Products