import React, {useContext, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {motion} from 'framer-motion'
import Icofont from 'react-icofont'

import {ThemeContext} from '../context/themeContext'
import Header from '../components/Header'
import Hero from '../components/Hero'
import HomeCta from '../components/HomeCta'
import Works from '../components/Works'
import Partners from '../components/Partners'
import ToContact from '../components/ToContact'
import Footer from '../components/Footer'

function Home(){

    const {afterLoading, onScrollGreenAnim, onScrollBottomAnim} = useContext(ThemeContext)

    useEffect(() => {
        afterLoading()
    }, [])

    useEffect(() => {
        window.addEventListener("scroll", onScrollBottomAnim);
        return () => window.removeEventListener("scroll", onScrollBottomAnim);
    }, [])

    useEffect(() => {
        window.addEventListener("scroll", onScrollGreenAnim);
        return () => window.removeEventListener("scroll", onScrollGreenAnim);
    }, [])

    return(
        <div>
            <motion.div 
                initial={{y:0}}
                animate={{y:'-100%'}}
                exit={{y:0}}
                transition={{ duration: 1, ease: [0.43, 0.13, 0.23, 0.96] }}
                className="page-trans">
            </motion.div>
            <Header />
            <Hero>
                <div className="row">
                    <div className="col-lg-7 col-12">
                        <h1 className="hero-title">
                           Welcome to<br /> CGC Investment Ltd <br />
                        </h1>
                        <p className="hero-title d-none d-lg-block">
                        CGC Investment ltd has been initiated with an aim to invest profitably and to be the solutions to the problems especially in electrical matters, constructively and sustainably in Rwanda. 
                        </p>
                        <div className="hero-button hero-title">
                            <Link to="/about">Learn more <Icofont icon="thin-double-right" /></Link>
                        </div>   
                    </div>
                </div>
            </Hero>
            <HomeCta />
            <Works />
            <Partners />
            <ToContact />
            <Footer />
        </div>
    )
}

export default Home