import React from 'react'

function Categories(){

    return(
        <div>
            <div className="widget anim-bot">
                <h5 className="sub-title">Categories</h5>
                
                <ul>
                    <li><a href="/">UI/UX Design</a></li>
                    <li><a href="/">Learning/Course</a></li>
                    <li><a href="/">Programming</a></li>
                    <li><a href="/">Fashion</a></li>
                </ul>
            </div>
        </div>
    )

}

export default Categories